<template>
  <div class="topic-box">
    <NuxtLink :to="linkTo(info)">
      <div class="title">
        <span class="title-badge">{{ getTopicType(info.tmtype) }}</span>
        <h4 class="title-wrap" v-html="title" v-html-image></h4>
      </div>
      <template v-if="tmnr">
        <div class="topic-wrap" v-html="tmnr" v-html-image></div>
      </template>
    </NuxtLink>
    <div class="answer" v-if="topic.answer">
      <span class="answer-title">答案：</span>
      <span class="answer-wrap" v-html="topic.answer" v-html-image></span>
    </div>
    <div class="btn-group">
      <el-button :loading="loading" v-if="info.btntxt && !viewed"
                 @click.native="() => viewAnswer(false)">{{info.btntxt }}</el-button>
      <button v-if="(info.stfrom === 0 || info.stfrom)  && $device.isDesktop"
              class="mobile"
              @click.native="(e) => onMouseover(info, e)">手机看题</button>
    </div>

    <div class="analysis"  v-if="topic.analysis">
      <span class="analysis-title">答案解析：</span>
      <span class="analysis-wrap" v-html="topic.analysis" v-html-image></span>
    </div>
  </div>
  <ClientOnly>
    <el-popover
      ref="popoverRef"
      :virtual-ref="tooltipRef"
      trigger="click"
      placement="right"
      :effect="effect"
      virtual-triggering
    >
        <div class="qrcode-box">
          <qrcode-vue :value="qrcode.url" :size="120" level="Q" :margin="2" :image-settings="qrcode.config" />
          <span>微信扫码免费搜题</span>
        </div>
    </el-popover>

  </ClientOnly>

</template>

<script setup>
import QrcodeVue from 'qrcode.vue'
import { getStAnswer } from '~/api'

const modalStore = useModalStore()
const store = useUserStore();

const qrcode = reactive({
  url: '',
  config: {
    src: 'https://static.ppkao.com/www/images/zantiku.png',
    width: 25,
    height: 25,
    excavate: true
  }
}); //二维码配置
const { info } = defineProps({
  info: {
    type: Object,
    default: {},
    required: true
  }
});
const topic = reactive({answer: info.answer || '', analysis: info.dajx || ''})
const title = computed(() => info.colortitle ?? info.title);
const tmnr = computed(() => info.colortmnr ?? info.tmnr);
const viewed = ref(false);
const effect = computed(() => useNuxtApp().$darkMode.isDarkMode.value ? 'dark' : 'light');
const tooltipRef = ref()
const loading = ref(false);


const onMouseover = (info, e) => {
  console.log('[点击]:', e.currentTarget)
  qrcode.url = `https://www.ppkao.com/mp/topic?st_id=${info.id}&st_from=${info.stfrom}&md5=true`;
  tooltipRef.value = e.currentTarget; // 将当前的 DOM 元素赋值给共享的 ref
}
const viewAnswer = async (disableModal) => {
  const { isLogin } = store.userInfo;
  if (isLogin) {
    loading.value = true;
    getStAnswer({
      examId: info.guid || info.id,
      stfrom: info.stfrom,
      md5: info.md5
    }).then(data => {
      if (data.S === '1') {
        if (data.Examlist[0].Exam_Type === '4') {
          data.Examlist[0].Answer =  data.Examlist[0].Answer === '1' ? '正确' : '错误';
        }
        topic.answer = data.Examlist[0].Answer;
        topic.analysis = data.Examlist[0].Analysis;
        viewed.value = true
      } else if (data.S === '-4' && !disableModal) {
        console.log('[跳转购买]')
        modalStore.setModal('pay', true, onLoginSuccess)
      } else {
        ElMessage({
          type: 'error',
          message: data.msg
        });
      }
    }).finally(() => {
      loading.value = false;
    });
  } else if(!disableModal){
    console.log('[打开弹框]');
    modalStore.setModal('scan', true, onLoginSuccess);
  }
}
const onLoginSuccess = () => {
  // 再去调用查看答案的接口
  modalStore.setModal('pay', false)
  modalStore.setModal('scan', false)
  viewAnswer();
}
if (process.client) {
  if (localStorage?.getItem('to_pay_result')) {
    localStorage.removeItem('to_pay_result');
    viewAnswer(true);
  }
}
</script>

<style scoped lang="scss">
.topic-box {
  @include flex-col;
  .title {
    margin-bottom: 10px;
    white-space: nowrap;
    &-badge {
      border-radius: 6px;
      @include font(var(--badge-text-color), 12px, 400);
      padding: 4px 8px;
      background-color: var(--badge-bg-color);
      margin-right: 8px;
      line-height: 24px;
      min-width: 52px;
      display: inline;
    }
    &-wrap {
      @include font(var(--primary-text-color), var(--global-font-size), 600);
      line-height: 26px;
      display: inline;
      white-space: normal; /* 自动换行 */
      word-wrap: break-word; /* 如果是很长的连续单词，也强制换行 */
      word-break: break-word; /* 确保断词换行 */
      :deep(p) {
        overflow-x: auto;
      }
    }
  }
  .topic-wrap {
    margin-top: 10px;
    margin-bottom: 10px;
    @include font(var(--primary-text-color), var(--topic-wrap-size), 400);
    line-height: 28px;
    white-space: normal; /* 自动换行 */
    word-wrap: break-word; /* 如果是很长的连续单词，也强制换行 */
    word-break: break-word; /* 确保断词换行 */
    :deep(p) {
      @include font(var(--primary-text-color), var(--topic-wrap-size), 400);
      line-height: 28px;
    }

  }
  .btn-group {
    @include flex-y-center;
    button, a {
      opacity: var(--bg-opacity);
      display: inline-block;
      padding: 0 10px;
      @include flex-center;
      margin-right: 10px;
      @include btn(auto, var(--look-btn-h), linear-gradient(180deg, #0479FF 30%, #7BB9FF 100%), 8px, 16px, #fff);
    }
    .mobile {
      background: linear-gradient(180deg, #202020 0%, #4E545B 100%);
      border: 1px solid var(--black-btn-border);
      height: 41px;
    }
  }
  .answer {
    margin-top: 20px;
    margin-bottom: 10px;
    &-title {
      @include font(var(--primary-text-color), 16px, 600);
    }
    &-wrap {
      @include font(var(--answer-color), 16px, 500);
      line-height: 24px;
    }
  }
  .analysis {
    margin-top: 26px;
    @include flex-col;
    &-title {
      @include font(var(--primary-text-color), 16px, 600);
    }
    &-wrap {
      @include font(var(--analysis-color), 16px, 400);
      line-height: 28px;
      margin-top: 10px;
    }
  }
}
:deep(.xcx_title ) {
  color: #e60012;
}
</style>
